import React from "react";
import { BsX } from "react-icons/bs";
import search from '../../icons/pictos/recherche_white.svg'

interface IProps {
  textFilter: string;
  onChange: Function;
  placeholder: string;
}


const SearchBarActualite = ({onChange, textFilter, placeholder}: IProps) => {
  const inputRef = React.useRef<HTMLInputElement>(null);

  const autocomplete = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(e.currentTarget.value);
  };

  const clearSearch = () => {
    let input = inputRef.current;
    if (input) {
      input.value = '';
    }

    onChange("");
  };
  
  return (
    <div>
      <div className="">
        <div className="tw-input-group">
          <div className="tw-bg-gray-500 tw-text-white tw-w-11 tw-flex tw-justify-center tw-items-center">
            {textFilter.length > 0 ? (
              <BsX
                onClick={clearSearch}
                className="tw-text-3xl tw-cursor-pointer"
              />
            ) : (
              <img src={search} style={{width:30, height:30}}/>
            )}
          </div>
          <input
            ref={inputRef}
            type="text"
            placeholder={placeholder}
            className="tw-input tw-input-bordered tw-w-full sm:tw-w-auto focus:tw-outline-none tw-text-black tw-bg-slate-50"
            onChange={autocomplete}
          />
        </div>
      </div>
    </div>
  );
};

export default SearchBarActualite;
