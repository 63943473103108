import { Link } from "gatsby";
import { isEmpty } from "lodash";
import React, { useEffect, useState } from "react";

type Image = {
    imageFile: { publicURL: string };
};

type Actualite = {
    title: string;
    content: string;
    image: Image;
    idNews: number;
    categoriesID: any;
};

const NewCards: React.FC<Actualite> = ({
    content,
    image,
    title,
    idNews,
    categoriesID,
}) => {
    const [img, setImg] = useState("");
    // const img = image?.imageFile?.publicURL;
    useEffect(() => {
        if (!isEmpty(image.imageFile)) {
            setImg(image.imageFile.publicURL);
        }
    }, []);

    return (
        <div className="tw-card tw-card-compact tw-w-72 tw-rounded-none tw-mr-7 tw-relative tw-h-full">
            <div>
                <figure className="tw-p-4">
                    <img
                        src={img}
                        alt="Shoes"
                        style={{ height: 200, width: "100%" }}
                    />
                </figure>
                <div className=" tw-text-gray-800">
                    <h2 className="tw-card-title tw-text-primary">{title}</h2>
                    <div
                        style={{ height: 130 }}
                        className="blogText"
                        dangerouslySetInnerHTML={{ __html: content }}
                    />
                </div>
            </div>
            <div className="tw-card-actions tw-absolute tw-bottom-0 tw-left-0 tw-p-5 tw-pt-0 tw-w-full tw-flex tw-justify-end">
                {categoriesID == "1" ? (
                    <Link
                        id="RouterNavLink"
                        to={"/activite/" + idNews}
                        className="tw-text-black"
                    >
                        Plus d'info
                    </Link>
                ) : (
                    <Link
                        id="RouterNavLink"
                        to={"/actualite/" + idNews}
                        className="tw-text-black"
                    >
                        Plus d'info
                    </Link>
                )}
            </div>
        </div>
    );
};

export default NewCards;
