import React, { useState, useLayoutEffect, useEffect } from "react";
import { debounce } from "lodash";
import Layout from "../layouts/layout";
import Partner from "../components/partner/partner";

import { useMapStore } from "../store/map";
import { useAddressStore } from "../store/address";
import MapPage from "./map";
import ListActualite from "../components/actualite/listactualite";
import CarouselBootstrap from "../components/carousel/carouselbootstrap";
import { graphql, PageProps, Link } from "gatsby";
import SearchBarActualite from "../components/search/search-bar-actualite";
import { formatFilterText, itextContains } from "../utils/utils";
import { getMapContext } from "../components/configs/map/configs_utils";

import dmd_new_adresse from "../icons/pictos/dmd_new_adresse.png";
import demande_de_certificat from "../icons/pictos/demande_de_certificat.png";
import exclamation_mark from "../icons/pictos/signale_erreur.png";

import Tooltip from "../components/tooltip/tooltip";

import BDA from "../components/home/bda";

const styly = {
    width: 80,
    height: 80,
};

const useWindowSize = () => {
    const [size, setSize] = useState([0, 0]);
    useLayoutEffect(() => {
        function updateSize() {
            setSize([window.innerWidth - 15, window.innerHeight - 290]); //469
            // setSize([window.innerWidth - 15, window.innerHeight - 120]);
        }
        window.addEventListener("resize", updateSize);
        updateSize();
        return () => window.removeEventListener("resize", updateSize);
    }, []);
    return size;
};

type DataProps = {
    directus: {
        news: [
            content: any,
            id: string,
            image: {},
            date_published: string,
            categories: {},
            title: string
        ];
    };
};

const IndexPage = ({ data, location }: PageProps<DataProps>) => {
    const mapContext = getMapContext(location.search);
    const {
        isClose,
        setClose,
        setControl,
        setPointerBuffer,
        setFlipMap,
        setOptions,
    } = useMapStore();
    const {
        setResults,
        setTextSearch,
        setLnglat,
        setDataAdress,
        setInfoFeatures,
        setUrlImage,
        setPathImage,
    } = useAddressStore();
    const showCarte = () => {
        setClose(true);
    };
    const [dataActualite, setDataActualite] = useState<any[]>([]);
    const [dataActualiteFilter, setDataActualiteFilter] = useState<any[]>([]);
    const [textFilter, setTextFilter] = useState("");

    const newsLimit = parseInt(process.env.NEWS_LIMIT!);

    //DETECTER SI LA PAGE CHANGE

    useEffect(() => {
        let aNews = data.directus.news;

        setDataActualite(aNews);
        setDataActualiteFilter(aNews.slice(0, newsLimit));

        return () => {
            setClose(false);
            setControl(null);
            setResults([]);
            setPointerBuffer("50");
            setTextSearch("");
            setLnglat(null);
            setFlipMap(true);
            setOptions([]);
            setDataAdress([]);
            setInfoFeatures([]);
            setUrlImage("");
            setPathImage("");
        };
    }, []);

    const onChangeFilter = debounce((strTextFilter: string) => {
        let aFilterData: any[] = [];

        strTextFilter = formatFilterText(strTextFilter);
        if (strTextFilter) {
            dataActualite.map((data) => {
                if (itextContains(data.title, strTextFilter)) {
                    aFilterData.unshift(data);
                } else if (itextContains(data.content, strTextFilter)) {
                    aFilterData.push(data);
                }
            });
        } else {
            aFilterData = dataActualite.slice(0, newsLimit);
        }

        setDataActualiteFilter(aFilterData);
        setTextFilter(strTextFilter);
    }, 750);

    // console.log('data directus >>>',dataActualite);

    const [width, height] = useWindowSize();
    if (width == 0 || height == 0) return null;

    const bShowMap = isClose || mapContext;
    const style = {
        height: width > 1024 ? height + "px" : "auto",
        width: "100%",
    };

    const styleIcon = {
        width: width > 1024 ? "70px" : "55px",
        height: width > 1024 ? "70px" : "55px",
    };

    return (
        <Layout>
            {!bShowMap ? (
                <button
                    className="tw-absolute tw-font-normal tw-z-10 tw-mt-[4%] tw-ml-11 tw-btn tw-btn-sm tw-bg-secondary tw-border-none"
                    onClick={() => showCarte()}
                >
                    Acceder à la carte
                </button>
            ) : (
                <></>
            )}

            {bShowMap ? (
                // style={{ height: height + "px", width: '100%' }}
                <div style={style} className="tw-min-h-[440px]">
                    {" "}
                    <MapPage mapContext={mapContext} />{" "}
                </div>
            ) : (
                <div className="tw-relative">
                    <CarouselBootstrap />
                </div>
            )}

            <div className="tw-bg-bgColor">
                {bShowMap ? <></> : <BDA />}

                <div
                    className={` tw-w-full tw-flex tw-justify-around ${
                        bShowMap ? "tw-mt-0" : "tw-mt-5"
                    } `}
                >
                    <div className="tw-flex tw-justify-around tw-pb-0 tw-w-full tw-min-h-full lg:tw-w-3/5">
                        <div className="tw-px-3 tw-text-xs sm:tw-px-10 sm:tw-text-base">
                            <div className="tw-rounded-full tw-pt-2 tw-px-3">
                                <Tooltip
                                    content="Nouvelle adresse"
                                    direction="bottom-center"
                                >
                                    <Link
                                        to="/_services/newadresse"
                                        className="tw-no-underline tw-text-black"
                                    >
                                        <img
                                            src={dmd_new_adresse}
                                            style={styleIcon}
                                            alt="new_adresse"
                                        />
                                    </Link>
                                </Tooltip>
                            </div>
                        </div>

                        {/* <div className="tw-px-3 tw-text-xs sm:tw-px-10 sm:tw-text-base ">
              <div className="tw-rounded-full tw-pt-2 tw-px-3">
                <Tooltip content="Demande de certificat" direction="bottom-center">
                  <Link
                    to="/_services/demande"
                    className="tw-no-underline tw-text-black"
                  >
                    <img src={demande_de_certificat} style={styleIcon} alt="certificat"/>
                  </Link>
                </Tooltip>
              </div>
            </div> */}

                        <div className="tw-px-3 tw-pt-1 tw-text-xs sm:tw-px-10 sm:tw-text-base">
                            <div className="tw-rounded-full tw-pt-2 lg:tw-px-3">
                                <Tooltip
                                    content="Signaler une erreur"
                                    direction="bottom-center"
                                >
                                    <Link
                                        to="/_services/erreur_adresse"
                                        className="tw-no-underline tw-text-black"
                                    >
                                        <img
                                            src={exclamation_mark}
                                            style={styleIcon}
                                            alt="erreur_adresse"
                                        />
                                    </Link>
                                </Tooltip>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="tw-space-y-4 tw-flex tw-justify-center">
                    <div
                        className={`${
                            bShowMap ? "tw-mt-2" : "tw-mt-3"
                        } tw-w-[75%]`}
                    >
                        <SearchBarActualite
                            onChange={onChangeFilter}
                            textFilter={textFilter}
                            placeholder="Rechercher des actualités"
                        />
                    </div>
                </div>
                <div className="tw-my-5">
                    <ListActualite
                        title="Actualités"
                        dataActualite={dataActualiteFilter}
                    />
                </div>

                <Partner bgColor={"#F6F5F5"} />
            </div>
        </Layout>
    );
};

export default IndexPage;

export const query = graphql`
    query directUS {
        directus {
            news(
                filter: {
                    categories: { categories_id: { id: { _eq: "2" } } }
                    status: { _eq: "published" }
                }
                sort: "-date_published"
            ) {
                id
                content
                image {
                    id
                    filename_download
                    imageFile {
                        publicURL
                    }
                }
                date_published
                categories {
                    categories_id {
                        id
                        name
                    }
                }
                title
            }
        }
    }
`;
