import React, { Fragment } from "react";
import { Link } from "gatsby";
import NewCards from "../card/newcards";

type Actualite = {
    title: string;
    dataActualite: any;
};

const ListActualite: React.FC<Actualite> = ({ title, dataActualite }) => {
    return (
        <div className="tw-space-y-4 tw-flex tw-justify-center">
            <div className="">
                <div className="tw-flex tw-justify-between">
                    <p className="tw-text-lg sm:tw-text-xl tw-font-bold tw-pb-1">
                        {title}
                    </p>
                    <div className="flex justify-around">
                        <Link
                            id="RouterNavLink"
                            to={"/actualite/"}
                            className="tw-bg-primary tw-px-2 tw-py-1 tw-text-white tw-rounded-lg tw-text-sm tw-mr-7"
                            style={{ textDecoration: "none" }}
                        >
                            Voir tout
                        </Link>
                    </div>
                </div>
                <div
                    className="tw-grid tw-grid-cols-1 md:tw-grid-cols-2 lg:tw-grid-cols-4 tw-gap-12 tw-mt-2"
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                        flexWrap: "wrap",
                    }}
                >
                    {dataActualite &&
                        dataActualite.map((item: any, index: number) => {
                            return (
                                <div
                                    key={index}
                                    className={
                                        index + 1 < dataActualite.length
                                            ? "tw-border-r-2  "
                                            : ""
                                    }
                                >
                                    <NewCards
                                        content={item.content}
                                        image={item.image}
                                        title={item.title}
                                        idNews={item.id}
                                        categoriesID={
                                            item.categories[0].categories_id.id
                                        }
                                    />
                                </div>
                            );
                        })}
                    {dataActualite.length == 2 && (
                        <div className="tw-block tw-w-80 tw-h-3" />
                    )}
                </div>
            </div>
        </div>
    );
};

export default ListActualite;
