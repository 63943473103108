import React from "react";
import Carousel from "react-bootstrap/Carousel";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { useStaticQuery, graphql } from "gatsby";

const CarouselBootstrap = () => {
    const data = useStaticQuery(graphql`
        query {
            directus {
                carousel {
                    image {
                        imageFile {
                            childImageSharp {
                                gatsbyImageData(height: 600)
                            }
                        }
                        id
                    }
                    id
                }
            }
        }
    `);

    return (
        <Carousel fade={true} interval={3000} className="tw-h-[600px]">
            {data.directus.carousel.map((dt: any) => {
                const image = getImage(dt.image.imageFile);

                if (!image) return null;

                return (
                    <Carousel.Item key={dt.image.id}>
                        <GatsbyImage
                            image={image}
                            alt={dt.image.id}
                            className="tw-w-full tw-h-[600px]"
                        />
                    </Carousel.Item>
                );
            })}
        </Carousel>
    );
};

export default CarouselBootstrap;
